<template>
  <div class="flex justify-center items-center h-screen">
    <div class="text-center">
      <p class="text-2xl font-bold mb-4">ACCUonMe表單服務</p>
      <p class="text-sm">如需要更多關於ACCUonMe的資訊，請洽ACCUonMe經銷商。</p>
    </div>
  </div>
</template>

<script>
import "tailwindcss/dist/tailwind.css";

export default {
  name: "WelcomeView",
};
</script>
<style scoped>
.home {
  margin-top: 150px;
  text-align: center;
  width: 100%;
}
</style>
