import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/element.js";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";
import { db, functions } from "./firebase";

if (location.hostname === "localhost") {
  connectFirestoreEmulator(db, "localhost", 9000);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
